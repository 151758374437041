@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");
body,
html {
  margin: 0px;
  padding: 0px;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  color: #383838;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
}
aside,
details,
figcaption,
figure,
ins,
summary,
mark,
title,
video,
footer,
header,
hgroup,
nav,
section,
article {
  display: block;
}
.clear {
  padding: 0;
  margin: 0;
  line-height: 0;
  height: 0;
  font-size: 0;
  clear: both;
  width: 100%;
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Mulish", sans-serif;
}
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}
body * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}
ul,
ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "Mulish", sans-serif;
}
input,
button {
  outline: none;
  border: none;
  box-shadow: none;
}
a,
a:hover,
a:focus {
  text-decoration: none !important;
  outline: none;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
a:active,
a:hover {
  outline: 0;
}
button {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  outline: none;
  border: none;
  outline: none;
}
:focus {
  outline: none;
  border: none;
}
button:focus {
  outline: none;
}
img {
  border: none;
}
p {
  margin: 0;
  padding: 0;
  color: #383838;
}
figure {
  margin: 0;
}

/* ===================== Placeholder ==================*/
input::placeholder {
  opacity: 1;
}
input::-webkit-input-placeholder {
  opacity: 1;
}
input::-moz-placeholder {
  opacity: 1;
}
input:-ms-input-placeholder {
  opacity: 1;
}
input:-moz-placeholder {
  opacity: 1;
}

header {
  background: #000000;
  padding: 10px 0;
}
.menu {
  margin: 0;
}
.menu li {
  display: block;
}
.menu li a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  font-family: "Mulish", sans-serif;
  padding: 0 20px;
}

.header .hireTalent {
  display: flex;
  margin: 0;
  padding: 0;
}
.header .hireTalent li {
  display: inline-block;
}
.header .hireTalent li + li {
  margin-left: 10px;
}
.header .hireTalent li a {
  color: #fff;
  border: 1px solid #595959;
  height: 50px;
  min-width: 132px;
  display: inline-block;
  line-height: 50px;
  border-radius: 4px;
  font-size: 16px;
  text-decoration: none;
  font-family: "Mulish", sans-serif;
  padding: 0 20px;
}
.header .hireTalent li:last-child a {
  background: #2737c3;
  border-color: #000000;
}

.banner h1 {
  font-size: 72px;
  font-weight: 500;
  margin-bottom: 30px;
}
.banner h1 span {
  display: block;
}
.banner p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
}
.banner a {
  color: #2737c3;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  margin-top: 30px;
}
.banner a img {
  margin-left: 50px;
  transition: all 0.5s;
}
.banner a:hover img {
  margin-left: 35px;
}

.on_demand_services_box {
  background: url(/public/images/service-background.svg) center / cover #000;
  padding: 40px;
}
.on_ser_inner {
  padding: 60px;
}
.on_ser_inner h2 {
  font-size: 36px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 30px;
}
.on_ser_main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}
.on_ser_main .on_ser_item {
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.on_ser_main .on_ser_item:hover {
  border-color: #2737c3;
  box-shadow: 0 5px 0 #2737c3;
}
.on_ser_main .on_ser_item p {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  width: 98px;
}
.on_ser_main .on_ser_item figure {
  align-self: flex-start;
  margin: 0;
}

.buildSection {
  padding: 50px 0;
}
.build_item {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 25px;
  height: 100%;
}
.bTitle {
  position: absolute;
  text-align: left;
}
.bTitle h2 {
  font-size: 113px;
  font-weight: 700;
  color: #000;
  line-height: 88px;
}
.bTitle p {
  font-size: 63px;
  font-weight: 700;
  color: #000;
}
.build_cnt h3 {
  font-size: 36px;
  font-weight: 700;
  color: #000;
  line-height: 50px;
}
.build_cnt p {
  font-size: 24px;
  font-weight: 600;
  color: #4a4a4a;
  line-height: 35px;
}
.build_cnt a {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  display: inline-block;
  margin-top: 25px;
}
.build_cnt a img {
  margin-left: 30px;
  transition: all 0.5s;
}
.build_cnt a:hover {
  color: #2737c3;
}
.build_cnt a:hover img {
  margin-left: 20px;
  -webkit-filter: hue-rotate(220deg) saturate(5);
  filter: hue-rotate(220deg) saturate(5);
}

.about_company {
  padding: 70px 0;
}
.about_us {
  padding: 40px;
  background: #f6f6f6;
}
.about_us span {
  text-transform: uppercase;
  font-size: 14px;
  color: #2737c3;
  font-weight: 400;
  display: block;
  margin-bottom: 10px;
}
.about_us h3 {
  font-size: 36px;
  font-weight: 900;
  color: #000;
  margin-bottom: 30px;
}
.about_us p {
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  line-height: 30px;
  padding-bottom: 40px;
}
.s_box {
  background: #fff;
  border-radius: 10px;
  padding: 35px;
}
.s_box ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.s_box ul li {
  border: 1px solid #595959;
  padding: 10px;
  background: #ebf2f4;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s_box ul li:hover {
  background: #fff;
}
.illustrater {
  position: absolute;
  top: 0;
  bottom: 150px;
  right: 0;
  margin: auto;
  width: 800px;
  height: 800px;
  left: 150px;
}
.illustrater img {
  width: 100%;
}

.s_box_2 {
  background: #fff;
  border-radius: 0px;
  padding: 20px;
  border: 1px solid #000;
}
.s_box_2 ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.s_box_2 ul li {
  flex: 0 0 50%;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}
.s_box_2 ul li img {
  margin-right: 5px;
  transition: all 0.5s;
}
.s_box_2 ul li:hover img {
  margin-right: 10px;
}

.team_section {
  background: url(/public/images/lineBg.svg) #000;
  padding: 70px 0;
}
.team_title span {
  text-transform: uppercase;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  display: block;
  margin-bottom: 10px;
}
.team_title h3 {
  font-size: 36px;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 30px;
}

.team_box {
  background: rgba(15, 15, 15, 1);
  padding: 50px;
  border-radius: 10px;
  border: 1px solid #fcfcfc;
  text-align: center;
}
.team_box:hover {
  background: rgba(39, 55, 195, 0.1);
  border-color: rgba(39, 55, 195, 1);
}
.team_box h3 {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
}
.team_box p {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 30px;
}
.team_box a {
  background: rgba(217, 217, 217, 0.06);
  display: inline-block;
  border-radius: 6px;
  width: 56px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.how_can_help {
  background: #f6f6f6;
  padding: 70px 0;
}
.how_can_help .team_title span {
  color: #2737c3;
}
.how_can_help .team_title h3 {
  color: #000;
}
.item_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 40px;
}
.item_container .it_item {
  transition: all 0.5s;
  background: #fff;
  padding: 30px;
  position: relative;
}
.item_container .it_item::after {
  width: 50px;
  height: 10px;
  background: #000;
  content: "";
  position: absolute;
  top: 0;
  margin-top: 69px;
  right: -50px;
}
.item_container .it_item:last-child::after {
  display: none;
}
.item_container .it_item h3 {
  font-size: 30px;
  font-weight: 700;
  color: #303030;
}
.item_container .it_item p {
  font-size: 18px;
  font-weight: 500;
  color: #575757;
}
.item_container .it_item:hover {
  background: #000;
  box-shadow: 0 10px 30px rgba(39, 55, 195, 0.3);
}
.item_container .it_item:hover h3,
.item_container .it_item:hover p {
  color: #fff;
}

.info_tech {
  padding: 70px 0;
}
.info_text .info_title {
  flex: 0 0 25%;
  width: 25%;
  background: #000000;
  color: #fff;
}
.info_text .info_content {
  flex: 0 0 75%;
  width: 75%;
  background: #fff;
  color: #fff;
  border-width: 1px 0;
  border-color: #000 transparent;
  border-style: solid;
}
.info_text .info_content ul {
  padding: 0;
}
.info_text .info_content ul li {
  font-size: 18px;
  color: #0b0b0b;
  line-height: 30px;
  padding: 20px 60px;
}
.info_text .info_content ul li + li {
  border-top: 1px solid #000;
}
.info_text .info_content ul li:hover {
  color: #2737c3;
  background: url(/public/images/line-arrow.svg) right center no-repeat;
}
.info_title {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  padding: 39px 46px 0px 0px;
}
.info_title h3 {
  font-size: 85px;
  color: #b9b9b9;
  font-weight: 700;
}
.info_title p {
  font-size: 65px;
  color: #fff;
  font-weight: 700;
}

.serSection {
  background: url(http://localhost:3000/static/media/lineBg.4ffb5e2c94c2003682fe.svg)
    #000;
  padding: 70px 0;
}
.serSection .serBox {
  background: url(/public/images/srBg.svg) no-repeat right center #0a0a0a;
  padding: 25px;
  border: 1px solid #595959;
  border-radius: 10px;
}

.serSection .serBox ul {
  padding: 0;
}
.serSection .serBox ul li {
  margin-bottom: 30px;
}
.serSection .serBox ul li button {
  background: none;
  font-size: 30px;
  color: #595959;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.serSection .serBox ul li img {
  margin-right: 20px;
}
.cntBox {
  padding: 50px;
  border: 1px solid #595959;
  border-radius: 10px;
  background: #0a0a0a;
  height: 100%;
}
.cntBox h4 {
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 15px;
}
.cntBox p {
  color: #fff;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
}
.cntBox a {
  background: #2737c3;
  display: inline-block;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 8px 20px 10px 20px;
  line-height: 14px;
}
.logoList {
  padding: 0;
  margin-top: 50px;
}
.logoList li {
  margin-right: 30px;
  text-align: center;
}
.logoList figure {
  padding: 0;
}
.logoList h5 {
  font-size: 16px;
  color: #999999;
  font-weight: 400;
}

.our_client {
  padding: 70px 0;
}
.our_client p {
  font-size: 18px;
}
.our_client .team_title h3 {
  color: #000;
  margin-bottom: 0;
}
.cl_main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
.cl_main .cl_logo {
  /* background: #f5f5f5; */
  border-radius: 10px;
  text-align: center;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project_detail {
  padding: 150px 0 70px;
}
.project_list {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  text-align: center;
  gap: 10px;
}
.project_list li {
  align-self: end;
  position: relative;
  width: 106px;
}
.project_list li::before {
  width: 60px;
  height: 1px;
  background: #000;
  content: "";
  position: absolute;
  margin: 0px 0px 0 -30px;
  bottom: 0;
}
.project_list li::after {
  height: 80px;
  width: 1px;
  background: #000;
  content: "";
  display: inline-block;
  margin: 0px 0 -6px 0;
}
.project_list li:nth-child(2)::after,
.project_list li:nth-child(6)::after,
.project_list li:nth-child(10)::after {
  height: 238px;
}
.project_list li:nth-child(4)::after,
.project_list li:nth-child(8)::after {
  height: 170px;
}
.project_list li .sm_box {
  transition: all 0.5s;
  width: 106px;
  height: 106px;
  border-radius: 50%;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.project_list li .sm_box h6 {
  color: #383838;
  font-size: 30px;
}
.project_list li .sm_box p {
  color: #383838;
  font-size: 14px;
}

.project_list li .medium_box {
  transition: all 0.5s;
  width: 127px;
  height: 127px;
  border-radius: 50%;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.project_list li .medium_box h6 {
  color: #383838;
  font-size: 36px;
}
.project_list li .medium_box p {
  color: #383838;
  font-size: 16px;
}

.best_website {
  padding: 0px 0 70px;
}
.best_website h4 {
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 25px;
}
.best_website p {
  font-size: 36px;
  font-weight: 400;
  line-height: 60px;
}
.best_website p b {
  font-weight: 900;
}
.quest {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  margin-top: -250px;
  padding-top: 70px;
  position: relative;
}

.project_list li .large_box {
  width: 194px;
  height: 194px;
  border-radius: 50%;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -193px;
  left: -46px;
  background: #fff;
  transition: all 0.5s;
}
.project_list li .large_box h6 {
  color: #383838;
  font-size: 42px;
}
.project_list li .large_box p {
  color: #383838;
  font-size: 18px;
}

.project_list li:hover .medium_box,
.project_list li:hover .large_box,
.project_list li:hover .sm_box {
  background: #2737c3;
}
.project_list li:hover .medium_box h6,
.project_list li:hover .large_box h6,
.project_list li:hover .sm_box h6,
.project_list li:hover .medium_box p,
.project_list li:hover .large_box p,
.project_list li:hover .sm_box p {
  color: #fff;
}
.project_list h6,
.project_list p {
  margin: 0;
}

.blog {
  margin-bottom: 70px;
}
.blog_title {
  margin-bottom: 25px;
}
.blog_title h3 {
  font-size: 36px;
  font-weight: 800;
  color: #000;
}
.blog_title a {
  border: 1px solid #949494;
  border-radius: 5px;
  display: inline-block;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.blog_title a:hover {
  background: #2737c3;
  color: #fff;
  border-color: #2737c3;
}
.blog_cnt span {
  font-size: 12px;
  display: block;
}
.blog_cnt h5 {
  font-size: 20px;
  font-weight: 700;
  margin: 15px 0;
}
.blog_cnt p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}
.blog_cnt a {
  color: #000;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.5s;
}
.blog_cnt a svg {
  font-size: 20px;
  margin-left: 10px;
  transition: all 0.5s;
}
.blog_cnt a:hover {
  color: #2737c3;
}
.blog_cnt a:hover svg {
  margin-left: 20px;
  transition: all 0.5s;
}

.ecom_main {
  padding-bottom: 70px;
}
.ecome_app {
  background: #000;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.ecome_app .ecom_inner {
  width: 100%;
  padding: 50px;
}
.ecome_app .ecom_inner h4 {
  color: #fff;
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 15px;
}
.ecome_app .ecom_inner p {
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
}
.ecome_app .ecom_inner a {
  background: #2737c3;
  color: #fff;
  padding: 12px 20px;
  display: inline-block;
  border-radius: 10px;
}
.ecome_app .ecom_inner a svg {
  font-size: 25px;
  margin-left: 10px;
  transition: all 0.5s;
}
.ecome_app .ecom_inner a:hover svg {
  margin-left: 50px;
}
.objImg img {
  position: absolute;
  bottom: -10px;
  right: -4px;
}

/*---------- Footer ------------*/
footer {
  background: #000;
  padding: 70px 0 20px;
}
footer h5 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
}
.ft_logo {
  position: relative;
  margin-bottom: 40px;
}
.ft_logo::after {
  width: 100%;
  height: 1px;
  content: "";
  display: inline-block;
  background: #3e3e3e;
  position: absolute;
  top: 45px;
  left: 0;
}
.ft_logo img {
  position: relative;
  z-index: 1;
  background: #000;
  padding-right: 25px;
}
.ft_menu {
  margin: 0;
  padding: 0;
}
.ft_menu li {
  margin-bottom: 15px;
}
.ft_menu li a {
  color: #fff;
  font-size: 14px;
}
.ft_menu li a svg {
  margin-right: 10px;
  transition: all 0.5s;
}
.ft_menu li a:hover {
  color: #2737c3;
}
.ft_menu li a:hover svg {
  margin-right: 25px;
}
.company p {
  color: #fff;
  font-size: 14px;
  line-height: 35px;
}
.address li {
  display: flex;
  color: #fff;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 35px;
  padding: 0;
}
.address li div {
  width: 50px;
  text-align: left;
  flex: 0 0 50px;
}
.address li svg {
  font-size: 18px;
  color: #2737c3;
}
.address li a {
  color: #fff;
}
.social_media {
  display: flex;
  gap: 15px;
  margin-top: 35px;
  padding: 0;
}
.social_media li a {
  border: 1.7px solid #2737c3;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  display: block;
  color: #9d9d9d;
}
.social_media li a svg {
  font-size: 17px;
}
.copyright {
  padding-top: 50px;
}
.copyright p {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}
.copyright p span {
  color: #2737c3;
}
.blankSpace {
  display: flex;
  align-items: end;
  justify-content: end;
}
.blankSpace li {
  background: #0a0a0a;
  width: 85px;
  height: 37px;
  margin-left: 25px;
}
/*---------- End Footer ------------*/
/*---------- Service Page ------------*/
.service_header {
  padding: 20px 0;
}
.service_header h1 {
  font-size: 100px;
  font-weight: 800;
  color: #000;
}
.all_services {
  padding: 20px 0 70px;
}
.ser_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 20px;
}
.ser_list .s_items {
  border: 2px solid #000000;
  border-radius: 20px;
  padding: 50px;
  min-height: 450px;
  transition: all 0.5s;
  position: relative;
}
.ser_list .s_items h2 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 15px;
}
.ser_list .s_items p {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 40px;
  min-height: 160px;
}
.ser_list .s_items figure {
  text-align: right;
}
.ser_list .s_items:hover {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(39, 55, 194, 1) 100%
  );
  transition: all 0.5s;
}
.ser_list .s_items:hover h2,
.ser_list .s_items:hover p {
  color: #fff;
}
.ser_list .s_items:hover img {
  filter: invert(1);
}

.project_card {
  padding: 0 0px 70px;
}
.project_card_inner {
  border: 1px solid #000;
  padding: 25px;
  border-radius: 20px;
}
.project_card_inner h1 {
  font-size: 46px;
  font-weight: 800;
  margin-bottom: 20ox;
  color: #000;
  line-height: 61px;
  margin-bottom: 25px;
}
.project_card_inner a {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 16px 30px;
  background: #2737c3;
  border-radius: 6px;
  display: inline-block;
}
.project_card_inner a:hover {
  background: #000;
}
.ser_header {
  padding-top: 70px;
}
.ser_header h1 {
  font-size: 72px;
  color: #000;
  font-weight: 800;
}
.ser_header p {
  font-size: 24px;
  color: #000;
  font-weight: 400;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  border-bottom: 1px solid #bdbdbd;
  line-height: 50px;
}
.ser_header .description {
  margin-top: -50px;
}
.ser_header a {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 16px 30px;
  background: #2737c3;
  border-radius: 6px;
  display: inline-block;
  margin-top: 50px;
}

/*---------- Service (1) Website Detail Page ------------*/
.cloth_banner {
  padding: 70px 0;
}
.responsiveImage {
  background: rgb(77, 53, 222);
  background: linear-gradient(
    186deg,
    rgba(77, 53, 222, 1) 0%,
    rgba(253, 157, 13, 1) 100%
  );
  gap: 20px;
  padding: 50px;
  border-radius: 20px;
}
.website_dev {
  padding-bottom: 70px;
}
.create_website h2 {
  font-size: 36px;
  font-weight: 800;
  color: #000;
  line-height: 45px;
  margin-bottom: 20px;
}
.create_website p {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  line-height: 40px;
  margin: 0;
}
.list_items {
  padding: 0;
}
.list_items li {
  position: relative;
  font-size: 17px;
  line-height: 40px;
}
.list_items li::before {
  width: 8px;
  height: 8px;
  background: #000000;
  border-radius: 50%;
  content: "";
  display: inline-block;
  margin-right: 10px;
}
.shadow_img {
  box-shadow: 8px 9px 16px #e8e8e8;
}
.shadow_img img {
  width: 100%;
}
.web_logo ul {
  background: url(/public/images/iconBg.png) no-repeat center #000;
  display: flex;
  justify-content: space-between;
  padding: 70px 100px;
  border-radius: 10px;
  background-size: cover;
}
.product {
  background: url(/public/images/iconBg.png) no-repeat center #000;
  display: flex;
  justify-content: space-between;
  padding: 70px 50px;
  border-radius: 10px;
  background-size: cover;
}
.product h3 {
  color: #fff;
  font-size: 46px;
  font-weight: 800;
  margin-bottom: 20px;
}
.product p {
  color: #fff;
  font-size: 18px;
  line-height: 32px;
}
.web_logo ul li {
  text-align: center;
}
.web_logo ul li figure {
  width: 106px;
  height: 106px;
  border-radius: 20px;
  background: #fff;
  margin: 0 auto;
  line-height: 106px;
  margin-bottom: 15px;
}
.web_logo ul li figcaption {
  font-size: 18px;
  color: #929292;
}
.title_bost h2 {
  font-size: 36px;
  font-weight: 800;
  line-height: 50px;
  margin-bottom: 25px;
}
.title_bost p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 25px;
}

.boost_main {
  padding: 70px 0;
}
.bost_box {
  background: #f5f5f5;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 25px;
  box-shadow: 0 14px 24px rgba(0, 0, 0, 0.1);
  height: 100%;
}
.bost_box img {
  width: 80px;
}
.bost_box h3 {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 15px;
}
.bost_box p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 30px;
}
.create_website_2 p {
  line-height: 30px;
  margin-bottom: 15px;
  font-size: 16px;
}
.deal_top {
  background: #f6f6f6;
  padding: 70px 0;
}
.techLogo {
  background: #fff;
  border-radius: 20px;
  border: 1px solid #000;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
}
.techLogo li {
  flex-grow: 0 0 1;
  flex-basis: 150px;
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
  color: #5b5b5b;
}
.techLogo li img {
  height: 70px;
}

.faq {
  padding: 70px 0;
}
.faq .accordion-item {
  margin-bottom: 10px;
  border: none;
}
.faq .accordion-header button {
  border: none;
  border-radius: 15px !important;
  font-size: 18px;
  font-weight: 500;
  padding: 21px 35px;
  border: 1px solid #ccc;
  background: #fff;
}
.faq .accordion-header button.collapsed {
  background: #e6e6e6;
  border: none;
}
.faq .accordion-header button:focus {
  box-shadow: none;
}
/* .faq .accordion-item:first-of-type, .faq .accordion-item:last-of-type{border-radius: 0; border: none;} */
.faq .accordion-item:last-of-type {
  border-radius: 0;
  border: none;
}

.digi_container {
  padding: 70px 0;
}
.digitalBox {
  border: 2px solid #000;
  padding: 60px 100px;
  background: #fff;
  border-radius: 10px;
}
.digi_msg {
  background: #000;
  padding: 50px;
  position: relative;
  height: 100%;
}
.digi_msg h1 {
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 25px;
}
.digi_msg p {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  line-height: 32px;
  width: 80%;
}
.digi_msg .icon1 {
  position: absolute;
  right: -47px;
  top: -26px;
}
.icon2 {
  position: absolute;
  bottom: -22px;
  left: -28px;
}
.icon3 {
  position: absolute;
  bottom: -22px;
  right: -28px;
}
.totla_click {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 50px;
}
.totla_click .to_items {
  flex-basis: 45%;
  flex-grow: 1;
  background: #f4ba62;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 15px;
}
.totla_click .to_items span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.totla_click .to_items h2 {
  font-size: 56px;
  font-weight: 700;
  color: #000;
  margin: 0;
  padding-top: 100px;
}
.totla_click .to_items:nth-child(2) {
  background: #7deac3;
}
.totla_click .to_items:nth-child(3) {
  background: #7e88e0;
}
.totla_click .to_items:nth-child(4) {
  background: #f07777;
}

.traffic_sec h3 {
  font-size: 36px;
  color: #040404;
  font-weight: 800;
  margin-bottom: 25px;
}
.traffic_sec p {
  font-size: 20px;
  color: #030303;
  font-weight: 400;
  line-height: 32px;
  width: 80%;
}
.key_sec {
  padding: 70px 0;
}
.key_title h3 {
  font-size: 36px;
  color: #040404;
  font-weight: 800;
  margin-bottom: 25px;
}
.key_title p {
  font-size: 18px;
  color: #030303;
  font-weight: 400;
  line-height: 32px;
  width: 80%;
}

.key_main {
  margin-top: 20px;
  -webkit-column-count: 3;
  -webkit-column-gap: 10px;
  -moz-column-count: 3;
  -moz-column-gap: 0px;
  column-count: 3;
  column-gap: 0px;
}
.key_items {
  min-height: 306px;
  background: white;
  margin: 0 -1px -1px 0;
  padding: 35px;
  text-align: left;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  column-break-inside: avoid;
  border: 1px solid #c0bebe;
}
.key_items h3 {
  font-size: 24px;
  color: #000;
  font-weight: 700;
}
.key_items p {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  line-height: 30px;
}
.cnt h3 {
  font-size: 36px;
  color: #040404;
  font-weight: 800;
  margin-bottom: 25px;
}
.cnt p {
  font-size: 18px;
  color: #030303;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 15px;
}

.mob_banner {
  padding-top: 70px;
}
.mobile_gallery {
  padding: 70px 0;
}
.main_galler {
  display: flex;
  flex-grow: 3;
  flex-wrap: wrap;
  gap: 20px;
  background: #f5f5f5;
  padding: 50px 30px;
  border-radius: 10px;
}
.main_galler .gallery_item {
  flex-basis: 31%;
}
.main_galler .gallery_item img {
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.space_top {
  padding-top: 70px;
}
.space_bottom {
  padding-bottom: 70px;
}

/* .crm_soft{padding-bottom: 70px;} */

.crm_soft .crm_box {
  background: #000;
  display: flex;
  flex-grow: 1;
}

.crm_soft_title h3 {
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 15px;
}
.crm_soft_title p {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  line-height: 30px;
}
.crm_soft img {
  width: 500px;
}
.crm_soft .text-end {
  align-self: flex-end;
}
.crm_image {
  border: 2px solid #2737c3;
  padding: 25px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}
.crm_stra {
  background: #242424;
  border-radius: 30px;
  padding: 50px;
  max-width: 500px;
  width: 100%;
}
.crm_stra h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 20px;
}
.crm_stra p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.crm {
  background: #2737c3;
  border-radius: 10px;
  padding: 25px 50px;
}
.crm .crm_info {
  background: #fff;
  width: 225px;
  height: 225px;
  border-radius: 50%;
  text-align: center;
  line-height: 225px;
  text-transform: uppercase;
  color: #1705e7;
  font-size: 64px;
  font-weight: 800;
}
.crm_list {
  padding: 0;
}
.crm_list {
  width: 70%;
  margin: 0;
  position: relative;
}
.crm_list li {
  width: 100%;
  text-align: center;
  line-height: 100px;
  position: relative;
}
.crm_list li::after {
  width: 3px;
  height: 50px;
  background: #fff;
  content: "";
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
}
.crm_list .txt {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  flex-basis: 25%;
}
.crm_list::after {
  width: 100%;
  height: 4px;
  background: #fff;
  content: "";
  display: block;
  position: absolute;
  top: 100px;
}
.orde_title {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.black_bg {
  position: relative;
  overflow: hidden;
  padding-bottom: 70px;
}
.black_bg::before {
  width: 100%;
  height: 100%;
  background: #000;
  content: "";
  display: block;
  position: absolute;
  margin-top: 25%;
  z-index: -1;
}

.chose_crm_main {
  flex-grow: 1;
  flex-wrap: wrap;
  display: flex;
}
.chose_crm_main .chose_item {
  flex-basis: 50%;
  padding: 50px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.chose_crm_main .chose_item:nth-child(2) {
  border-right: none;
}
.chose_crm_main .chose_item h5 {
  font-size: 24px;
  color: #000;
  margin-bottom: 20px;
  font-weight: 600;
}
.chose_crm_main .chose_item ul {
  padding: 0;
  margin: 0;
}
.chose_crm_main .chose_item ul li {
  display: flex;
}
.chose_crm_main .chose_item ul li svg {
  font-size: 25px;
  margin-right: 10px;
}
.chose_crm_main .chose_item ul li p {
  width: 90%;
}
.chose_crm_main .chose_item:last-child {
  flex-basis: 100%;
}

.crm_things {
  padding: 70px 0;
}
.crm_things .crm_cnt {
  margin-bottom: 30px;
}
.crm_things .crm_cnt img {
  height: 60px;
  margin-bottom: 20px;
}
.crm_things .crm_cnt h3 {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 20px;
}
.crm_things .crm_cnt p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 10px;
}
.app_title {
  border-radius: 20px;
  overflow: hidden;
}
.app_title h3 {
  font-size: 40px;
}
.app_title h3 span {
  font-size: 85px;
}
.app_title img {
  height: auto;
  width: auto;
}
.benefit {
  padding-top: 70px;
}
.benefit h4 {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 50px;
}
.benefit h5 {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 15px;
}
.benefit ul {
  margin: 0;
  padding: 0;
}
.benefit ul li {
  margin-bottom: 30px;
}
.benefit p {
  font-size: 18px;
  line-height: 30px;
  color: #333333;
}
.img_box {
  background: #143b87;
  border-radius: 20px;
  box-shadow: inset 0 0px 50px rgba(215, 215, 215, 0.45);
}
.sale_buss {
  padding-top: 70px;
}
.sale_pipeline {
  margin-bottom: 50px;
}
.sale_pipeline h4 {
  font-size: 24px;
  color: #000;
  margin-bottom: 15px;
  font-weight: 700;
}
.sale_pipeline p {
  font-size: 18px;
  color: #333333;
  margin-bottom: 15px;
  font-weight: 400;
  line-height: 30px;
}
.sale_pipe figure {
  background: #f5f5f5;
  height: 100px;
  width: 100px;
  border-radius: 10px;
  text-align: center;
  line-height: 95px;
  margin-bottom: 30px;
}
.sale_pipe h5 {
  font-size: 24px;
  color: #000;
  margin-bottom: 15px;
  font-weight: 700;
  margin-bottom: 15px;
}
.sale_pipe p {
  font-size: 18px;
  color: #333333;
  margin-bottom: 15px;
  font-weight: 400;
  line-height: 30px;
}
.ux_box {
  border: 2px solid #000;
  border-radius: 10px;
  padding: 10px;
}
.mobile_bg {
  background: url(/public/images/mobile_bg.svg) 100% / cover;
  padding: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
}
.green_mobile_bg {
  background: url(/public/images/green_bg.svg) 100% / cover #f2f2f2;
  padding: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
}
.ui_cnt {
  background: #242424;
  border-radius: 10px;
  height: 100%;
  padding: 50px;
}
.ui_cnt h2 {
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 15px;
}
.ui_cnt p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
  line-height: 30px;
}
.grap_sec {
  position: relative;
  padding: 0;
}
.grap_sec::after {
  background: #000;
  width: 100%;
  height: 250px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  z-index: -1;
}
.grap_sec li:nth-child(2),
.grap_sec li:nth-child(4) {
  margin-top: 50px;
}
.ser_cnt {
  background: #030303;
  border-radius: 10px;
  padding: 40px;
  height: 100%;
}
.server_sec {
  background: url(/public/images/server_bg.svg) 100% / cover #fff;
  padding: 25px;
  border-radius: 10px;
  border: 2px solid #000;
}
.ser_cnt p {
  font-size: 20px;
  line-height: 32px;
  color: #fff;
}
.share_box {
  background: #6181ef;
  border-radius: 10px;
  min-height: 600px;
  position: relative;
  padding: 100px 50px;
  overflow: hidden;
}
.share_box::before {
  background: #fefefe;
  height: 93%;
  width: 100%;
  position: absolute;
  content: "";
  transform: rotate(5deg);
  left: 0;
  top: 0;
  z-index: 1;
  margin-top: 12%;
  transition: all 0.5s;
}
.share_box::after {
  background: #6181ef;
  height: 93%;
  width: 100%;
  position: absolute;
  content: "";
  transform: rotate(5deg);
  left: -14px;
  top: -20px;
  z-index: 0;
  margin-top: 12%;
  border: 1px solid #fff;
  transition: all 0.5s;
}
.share_box h3 {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}
.share_box p {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  line-height: 32px;
}
.share_box:hover {
  border: 1px solid #2737c3;
}
.share_box:hover::after {
  height: 100%;
  width: 100%;
  transform: rotate(0deg);
  left: 0px;
  top: 0px;
}
.share_box:hover::before {
  height: 100%;
  width: 100%;
  transform: rotate(0deg);
  left: 0px;
  top: 0px;
  margin-top: 0;
}
.app_desing {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  border: 2px solid #cfcfcf;
  padding: 12px 0;
}
.app_desing .app_list {
  flex-basis: 25%;
}
.app_desing .app_list:nth-child(2) {
  margin-top: 150px;
}

.image {
  overflow-y: scroll;
  height: 620px;
  border: 2px solid #cfcfcf;
  border-radius: 20px;
  padding: 20px;
}
.image div div:nth-child(2) img {
  margin-top: 100px;
}
.image div div:nth-child(4) img {
  margin-top: 100px;
}

.sft_img {
  border: 2px solid #000;
  border-radius: 10px;
  padding: 20px;
}
.sft_img img {
  border-radius: 10px;
}
.sft_bg {
  background: #222222;
  border-radius: 10px;
}
.type_soft-box {
  background: #f6f6f6;
  padding: 30px;
  margin-bottom: 25px;
}
.type_soft-box h3 {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 25px;
}
.type_soft-box p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
  letter-spacing: 2px;
}
.app_cnt p {
  margin-bottom: 20px;
}
.soft_list {
  margin: 0;
  padding: 0;
}
.soft_list li {
  font-size: 18px;
  line-height: 40px;
}
.soft_list li svg {
  font-size: 25px;
}
.looking {
  background: #f6f6f6;
  border-radius: 20px;
  padding: 50px;
}
.looking h3 {
  font-size: 38px;
  font-weight: 800;
  color: #000;
  margin-bottom: 15px;
}
.looking p {
  font-size: 18px;
  line-height: 30px;
}
.img_width {
  width: 210px;
}
.text_para {
  font-size: 18px;
  line-height: 30px;
  padding: 15px 0 10px;
}
.title_1 {
  background: #222222;
  font-size: 40px;
  border-radius: 10px;
  padding: 15px 25px;
  margin-bottom: 25px;
  color: #fff;
  font-weight: 800;
}
.para_txt {
  background: #222222;
  border-radius: 10px;
  padding: 50px 40px;
  min-height: 250px;
}
.para_txt p {
  font-size: 20px;
  color: #fff;
  line-height: 32px;
}
.soft_img img {
  width: 100%;
}
.soft_img-2 {
  text-align: right;
  padding-right: 25px;
  position: relative;
  top: 44px;
  z-index: -1;
}

.caarer {
  padding: 100px 0 70px;
}
.caarer h1 {
  font-size: 72px;
  font-weight: 800;
  margin-bottom: 20px;
}
.caarer p {
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 32px;
}
.careTitle img {
  position: absolute;
  right: 0;
  top: -70px;
}
.careTitle {
  position: relative;
}
.car_img img {
  border-radius: 10px;
  border: 1px solid #000;
}
.space_img {
  padding-top: 150px;
}
.img_galley {
  display: flex;
  gap: 20px;
  flex-grow: 1;
}
.img_galley div {
  flex-basis: 25%;
}
.galley_icon {
  display: flex;
  gap: 40px;
  flex-grow: 1;
}
.galley_icon div {
  flex-basis: 25%;
  text-align: center;
}
.galley_icon div figure {
  border: 1px solid #000;
  height: 180px;
  border-radius: 50%;
  width: 180px;
  line-height: 180px;
}

.blogDetail {
  background: #fafbfb;
}
.blog_list .title {
  font-size: 50px;
  margin-bottom: 15px;
  font-weight: 800;
  background: url(/public/images/blog-1.svg) no-repeat center;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  min-height: 350px;
  display: grid;
  align-content: center;
}
.col-md-6:nth-child(2) .title {
  background: url(/public/images/blog-2.svg) no-repeat center;
}
.col-md-6:nth-child(3) .title {
  background: url(/public/images/blog-3.svg) no-repeat center;
}
.col-md-6:nth-child(4) .title {
  background: url(/public/images/blog-4.svg) no-repeat center;
}
.col-md-6:nth-child(5) .title {
  background: url(/public/images/blog-5.svg) no-repeat center;
}
.col-md-6:nth-child(6) .title {
  background: url(/public/images/blog-6.svg) no-repeat center;
}
.blog_list h2 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.blog_list p.date {
  font-size: 12px;
  margin-bottom: 6px;
}
.blog_list p {
  font-size: 14px;
  line-height: 26px;
}
.blog_list a {
  color: #000;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.5s;
}
.blog_list a:hover {
  color: #2737c3;
}
.blog_list a svg {
  font-size: 20px;
  margin-left: 10px;
  transition: all 0.5s;
}
.blog_list a:hover svg {
  margin-left: 20px;
  transition: all 0.5s;
}
.blog_detail .title {
  height: 400px;
  font-size: 106px;
  background-size: cover;
}
.blog_detail h2 {
  font-size: 36px;
}
.blog_detail p.textBig {
  font-size: 24px;
  line-height: 38px;
}
.blog_detail p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 5px ;
}
.cnt_box {
  background: #f4f4f4;
  border-radius: 10px;
  padding: 50px;
}
.cnt_box p {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 40px;
}
.leader_cnt {
  background: #d9a828;
  position: relative;
  overflow: hidden;
}
.leader_cnt h2 {
  color: #000;
  font-size: 36px;
  font-weight: 800;
}
.leader_cnt p {
  color: #000;
}
.leader_cnt img {
  position: absolute;
  right: -70px;
  top: -70px;
}

.serMenu {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.serMenu li + li {
  margin: 0 20px 20px 0;
}
.serMenu li:first-child {
  margin-right: 20px;
}
.serMenu li a {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px 30px;
  display: block;
}
.serMenu li a:hover {
  border-color: #143b87;
  color: #143b87;
}
.bg_gry {
  background: #f2f2f2;
}
.ourClients {
  background: #f8f8f8;
  padding: 15px 0;
}

/*============================= Media Query ======================*/
@media all and (max-width: 1384px) {
  .banner h1 {
    font-size: 50px;
  }
  .header .item-center {
    /* flex: 0 0 60%; */
  }
  .logo img {
    width: 100px;
  }
  .header .item-left {
    /* flex: 0 0 10%; */
  }
  .header .item-right {
    /* flex: 0 0 25%; */
  }
  .header .hireTalent li a {
    padding: 0 14px;
    font-size: 12px;
    min-width: 92px;
  }
  .on_ser_main {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .build_cnt h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .bTitle h2 {
    font-size: 70px;
  }
  .bTitle p {
    font-size: 38px;
  }
  .team_box {
    padding: 14px 16px;
  }
  .team_box figure img {
    width: 100px;
  }
  .best_website {
    margin-top: 25px;
  }
  .best_website p {
    line-height: 34px;
  }
  .about_us h3 {
    font-size: 24px;
  }
  .cl_main {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .project_list li .large_box {
    left: -96px;
  }
  .careTitle img {
    width: 100px;
    top: 0;
  }
  .caarer {
    padding: 24px 0;
  }
  .caarer h1 {
    font-size: 50px;
  }
  .project_card_inner h1 {
    font-size: 30px;
    line-height: normal;
  }
  .galley_icon {
    overflow-y: scroll;
  }
  .ser_list {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  .ser_list {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  .ser_list .s_items h2 {
    font-size: 22px;
  }
  .ser_list .s_items p {
    font-size: 16px;
    line-height: 26px;
    min-height: auto;
  }
  .ser_list .s_items {
    padding: 26px;
    min-height: auto;
  }
  .ser_list .s_items figure img {
    width: 70px;
  }
  .service_header h1 {
    font-size: 50px;
  }
  .graphIcon img {
    width: 100px;
  }
  .looking h3 {
    font-size: 28px;
  }
  .ser_header h1 {
    font-size: 50px;
  }
  .ser_header figure img {
    width: 100px;
  }
  .ser_header .description {
    margin: 0;
  }
  .ser_header p {
    font-size: 20px;
    width: 100%;
  }
  .app_title h3 span {
    font-size: 60px;
  }
  .crm_soft img {
    width: 330px;
  }
  .create_website h2 {
    font-size: 30px;
  }
  .create_website p {
    font-size: 16px;
    line-height: inherit;
  }
  .key_items p {
    font-size: 14px;
    line-height: initial;
  }
  .key_items h3 {
    font-size: 20px;
  }
  .grap_sec {
    overflow-y: scroll;
    gap: 20px;
  }
}
@media all and (max-width: 450px) {
  .send-button {
    width: 300px !important;
  }
  .alt-send-button {
    width: 300px !important;
  }
}
@media all and (max-width: 1199px) {
  .serSection {
    background: #000;
    padding: 40px 0;
  }
  .cl_main {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .s_box {
    padding: 10px;
  }
  .s_box ul {
    gap: 5px;
  }
  .galley_icon {
    overflow-y: scroll;
  }
  .blog_detail .title {
    height: 400px;
    font-size: 60px;
  }
  .blog_list .title {
    min-height: 250px;
    font-size: 40px;
  }
  .ser_header figure {
    display: none;
  }
  .ser_header .description {
    margin-top: 0;
  }
  .ser_header h1 {
    text-align: center;
    font-size: 36px;
    width: 100%;
  }
  .ser_header p {
    width: 100%;
  }
  .ser_header p {
    font-size: 20px;
  }
  .looking h3 {
    font-size: 30px;
  }
  .img_width img {
    width: 100%;
  }
  .boost_main {
    padding: 30px 0;
  }
  .cnt h3 {
    font-size: 23px;
    margin-bottom: 10px;
  }
  .traffic_sec p {
    width: 100%;
  }
  .traffic_sec h3 {
    font-size: 24px;
  }
  .digitalBox {
    padding: 20px;
  }
  .crm_soft .text-end {
    display: none;
  }
  .key_items p {
    font-size: 16px;
    line-height: inherit;
  }
  .key_items h3 {
    font-size: 18px;
  }
  .product h3 {
    font-size: 30px;
  }
  .image div div:nth-child(2) img {
    margin-top: 20px;
  }
  .image div div:nth-child(4) img {
    margin-top: 20px;
  }
  .grap_sec {
    background: #000;
  }
}
@media all and (max-width: 991px) {
  .work_items {
    width: 100% !important;
  }
  .banner h1 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  .serMenu li + li {
    margin: 0;
  }
  .serMenu li {
    width: 100%;
    margin: 0;
  }
  .serMenu li a {
    border-width: 0 0 1px;
    border-color: #383838;
    border-style: solid;
    border-radius: 0px;
    padding: 16px 0px;
  }
  .serMenu li:first-child {
    margin: 0;
  }
  .logo img {
    width: 100px;
  }
  .on_ser_inner {
    padding: 30px 0;
  }
  .on_ser_main .on_ser_item p {
    font-size: 14px;
  }
  .on_ser_main {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }
  .build_item {
    margin-bottom: 20px;
    height: auto;
  }
  .team_box {
    margin-bottom: 25px;
  }
  .best_website p {
    font-size: 27px;
    line-height: 34px;
  }
  .best_website h4 {
    margin: 23px 0;
  }
  .serSection .serBox {
    background: none;
    border: none;
    padding: 15px;
  }
  .serSection .serBox ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  .serSection .serBox ul li button {
    border: 1px solid #595959;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px;
  }
  .serSection .serBox ul li button img {
    display: none;
  }
  .serSection .serBox ul li {
    margin: 0;
  }
  .item_container {
    overflow: auto;
    /* overflow: hidden; */
  }
  .cl_main .cl_logo img {
    width: 100px;
  }
  .careTitle img {
    width: 80px;
    top: 0;
  }
  .caarer {
    padding: 25px 0;
  }
  .caarer p {
    font-size: 16px;
    line-height: 26px;
  }
  .caarer h1 {
    font-size: 46px;
  }
  .cnt_box {
    padding: 20px;
  }
  .cnt_box p {
    font-size: 17px;
    line-height: 30px;
  }
  .leader_cnt img {
    right: -30px;
    top: -30px;
    width: 100px;
  }
  .space_bottom {
    padding-bottom: 40px;
  }
  .space_top {
    padding-top: 40px;
  }
  .project_card_inner h1 {
    font-size: 20px;
    line-height: normal;
  }
  .car_img img {
    margin-bottom: 20px;
  }
  .space_img {
    padding-top: 0;
  }
  .create_website p {
    line-height: 29px;
    margin-bottom: 25px;
    font-size: 16px;
  }
  .blog_list .title {
    min-height: 200px;
    font-size: 30px;
  }
  .service_header h1 {
    font-size: 40px;
  }
  .graphIcon img {
    width: 50px;
  }
  .ser_list {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .ser_header p {
    font-size: 16px;
    line-height: 35px;
    width: 100%;
  }
  .ser_header {
    padding-top: 40px;
  }
  .type_soft-box h3 {
    margin-bottom: 10px;
    font-size: 28px;
  }
  .looking {
    padding: 20px;
  }
  .looking h3 {
    font-size: 25px;
  }
  .img_width {
    display: none;
  }
  .web_logo ul {
    padding: 30px;
  }
  .web_logo ul {
    flex-wrap: wrap;
    gap: 15px;
  }
  .web_logo ul li {
    flex-basis: 45%;
  }
  .title_bost h2 {
    font-size: 26px;
    line-height: 33px;
  }
  .main_galler {
    gap: 10px;
    padding: 20px;
  }
  .key_title h3 {
    font-size: 25px;
  }
  .key_title p {
    width: 100%;
  }
  .deal_top {
    padding: 30px 0;
  }
  .create_website h2 {
    margin-bottom: 5px;
    font-size: 24px;
    line-height: initial;
  }
  .key_main {
    column-count: 2;
  }
  .totla_click .to_items h2 {
    font-size: 40px;
    padding-top: 22px;
  }
  .totla_click {
    gap: 10px;
    margin-right: 0;
  }
  .totla_click .to_items span {
    font-size: 13px;
  }
  .digi_msg .icon1,
  .icon2,
  .icon3 {
    display: none;
  }
  .digi_msg {
    padding: 25px;
    margin-top: 25px;
    height: auto;
  }
  .digi_msg h1 {
    font-size: 28px;
  }
  .digi_msg p {
    font-size: 16px;
    line-height: inherit;
    width: 100%;
  }
  .crm_image {
    flex-wrap: wrap;
  }
  .crm_stra {
    padding: 25px;
  }
  .crm_stra h2 {
    font-size: 25px;
  }
  .crm_stra p {
    font-size: 16px;
    line-height: inherit;
  }
  .crm_soft_title h3 {
    font-size: 25px;
  }
  .crm_soft_title p {
    font-size: 16px;
    line-height: inherit;
  }
  .black_bg {
    margin-top: 25px;
  }
  .black_bg::before {
    margin-top: 0;
  }
  .black_bg .key_title h3 {
    color: #fff;
  }
  .black_bg .key_title p {
    color: #fff;
  }
  .app_title h3 span {
    font-size: 31px;
  }
  .crm_stra {
    max-width: 100%;
  }
  .chose_crm_main .chose_item {
    padding: 20px;
  }
  .chose_crm_main .chose_item h5 {
    font-size: 18px;
  }
  .ui_cnt {
    padding: 20px;
  }
  .ui_cnt h2 {
    font-size: 24px;
  }
  .title_1 {
    font-size: 34px;
  }
  .para_txt {
    padding: 30px;
  }
  .ser_cnt {
    padding: 20px;
  }
  .ser_cnt p {
    font-size: 16px;
    line-height: inherit;
  }
}
@media all and (max-width: 767px) {
  .work_items .img-container {
    height: auto !important;
  }
  .banner h1 {
    font-size: 28px;
    font-weight: 500;
    margin: 10px 0;
  }
  .banner a {
    margin: 0 0 30px;
  }
  .on_ser_inner {
    padding: 0;
  }
  .on_ser_inner h2 {
    font-size: 28px;
    margin-top: 10px;
  }
  .build_cnt h3 {
    font-size: 20px;
    line-height: normal;
  }
  .build_cnt p {
    font-size: 16px;
    line-height: normal;
  }
  .bTitle {
    position: relative;
  }
  .bTitle h2 {
    font-size: 70px;
    line-height: normal;
    margin: 0;
  }
  .bTitle p {
    font-size: 50px;
  }
  .build_item {
    margin-bottom: 20px;
    height: auto;
  }
  .build_item img {
    width: 130px;
  }
  .build_cnt a img {
    width: 50px;
  }
  .about_company {
    padding-top: 0;
  }
  .about_us h3 {
    font-size: 26px;
  }
  .about_us {
    padding: 25px;
  }
  .s_box ul {
    flex-wrap: wrap;
    gap: 24px;
  }
  .s_box {
    padding: 20px;
  }
  .s_box ul li {
    width: 44%;
  }
  .illustrater {
    display: none;
  }
  .about_us p {
    padding-bottom: 0;
  }
  .s_box_2 ul li {
    flex: 0 0 100%;
  }
  .team_box {
    margin-bottom: 20px;
  }
  .cntBox {
    border: none;
    background: none;
    padding: 15px;
  }
  .logoList {
    flex-wrap: wrap;
  }
  .cl_main {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }
  .ecome_app .ecom_inner h4 {
    font-size: 23px;
  }
  .ecome_app .ecom_inner {
    padding: 30px;
  }
  .company p {
    line-height: 24px;
  }
  footer .col-lg-3 {
    margin-bottom: 30px;
  }
  .img_galley {
    flex-wrap: wrap;
  }
  .create_website h2 {
    font-size: 25px;
    margin: 0;
  }
  .project_card_inner h1 {
    font-size: 20px;
    line-height: normal;
  }
  .blog_detail .title {
    height: 200px;
    font-size: 36px;
    min-height: auto;
  }
  .blog_detail h2 {
    font-size: 20px;
  }
  .blog_detail p.textBig {
    font-size: 17px;
    line-height: 25px;
  }
  .blog_detail p {
    font-size: 15px;
    line-height: 26px;
  }
  .ser_list {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .ser_header h1 {
    font-size: 30px;
  }
  .soft_list li {
    font-size: 15px;
    line-height: 30px;
  }
  .techLogo {
    gap: 10px;
  }
  .techLogo li {
    flex-basis: 48%;
    margin: 0;
  }
  .key_main {
    column-count: 1;
  }
  .cnt {
    margin-top: 25px;
  }
  .cnt h3 {
    font-size: 25px;
  }
  .chose_crm_main .chose_item {
    flex-basis: 100%;
    padding: 20px;
  }
  .key_items {
    min-height: auto;
  }
}
.tech-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tech-img-container img {
  background: #141414;
  width: 70px;
  height: 70px;
  border-radius: 7px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}
.tech-img-container p {
  text-align: center;
}
.services-dropdown {
  display: flex;
  flex-direction: column;
}
/* .services-dropdown button {
  height: 37.6px;
  border-radius: 5px;
} */
.footer-dropdown {
  position: absolute;
  top: 65px;
  background: #143b87;
  width: -webkit-fill-available;
  border-radius: 5px;
  overflow-y: auto;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.project-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  width: 75%;
  justify-content: center;
  margin: auto;
}

.form-container {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  width: 80% !important;
  margin: auto;
  height: 90%;
}
.formcontainer-startproject {
  background: black;
  color: aliceblue;
  border-radius: 27px;
}
.submitbtn-startproject {
  /* background: #143b87; */
  width: -webkit-fill-available;
}
.startproject-container {
  /* background: #143b87; */
}
.formcontainer-startproject{
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.project-form-parent-container {
  position: absolute;
  /* position: fixed; */
  top: 0;
  z-index: 999;
  background: black;
  right: 0;
  left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-close-btn {
  /* margin-left: auto; */
  /* float: right; */
  font-size: 30px;
  position: relative;
  top: 50px;
  left: 87%;
}

.project-form .form-group {
  width: 48%;
}
.costing-container {
  display: flex;
  gap: 20px;
}
.costing-container .item-1 {
  width: 30%;
  /* flex: 1; */
}
.costing-container .item-2 {
  /* flex: 2; */
  width: 70%;
}
.w-100 {
  width: 100% !important;
}
.project-form label {
  font-size: 18px;
  font-weight: 500;
}

.form-control:focus {
  box-shadow: none !important;
}
.work_list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.all_services .work_items {
  border: none !important;
}
.work_items .img-container {
  border-radius: 20px;
}
.work_items {
  /* display: flex;
  gap: 20px; */
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px 10px;
  margin: 10px 0;
  width: 48%;
}
.work_items .project-details {
  /* width: 60%; */
  width: 100%;
}
.work_items .project-details h2 {
  font-weight: 800;
}
.work_items .img-container {
  /* width: 45%; */
  width: 100%;
  height: 330px;
}
.work_items .img-container img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
}
@media (max-width: 1000px) {
  .project-form .form-group {
    width: 47%;
  }
}
@media (max-width: 770px) {
  .project-form .form-group {
    width: 100%;
  }
}
.error-message {
  font-size: 12px;
  color: red;
  position: absolute;
}
.error-page {
  display: flex;
  text-align: center;
  padding: 50px;
  color: #2737c3;
  height: 60vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-page h1 {
  font-size: 40px;
}
.tech-icons-containers {
  width: 40px;
  height: 40px;
}
.tech-icons-containers img {
  width: 100%;
  /* height: 40px; */
}
