.header {
  display: block;
  width: 100%;
  position: relative;
  z-index: 99;
  padding: 15px;
}
.header-container {
  display: flex;
  justify-content: space-between;
}
.header .item-left {
  /* flex: 0 0 17%; */
}
.header .logo a {
  font-size: 30px;
  color: #000000;
  font-weight: 700;
  text-decoration: none;
}
.hireTalent a:hover {
  color: #9b9b9b !important;
}
.header .item-center {
  /* flex: 0 0 55%; */
  display: flex;
  align-items: center;
}
.header .item-right {
  /* flex: 0 0 28%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header .item-right a {
  text-decoration: none;
  font-size: 16px;
  color: #555555;
  display: inline-block;
  margin-left: 10px;
  transition: color 0.3s ease;
}
.header .menu-main {
  margin: 0;
  padding: 0;
}
.header .menu > ul > li {
  display: inline-block;
  line-height: 50px;
  margin-left: 0px;
}
.header .menu > ul > li > a {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  text-transform: capitalize;
  transition: color 0.3s ease;
}
.header .menu > ul > li .sub-menu {
  position: absolute;
  z-index: 500;
  background-color: #000000;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  padding: 20px 30px;
  transition: all 0.5s ease;
  margin-top: 25px;
  opacity: 0;
  visibility: hidden;
}

.ser_dropmenu .list-item {
  flex-basis: 30%;
}
.ser_dropmenu .list-item.list-item_2 {
  flex-basis: 70%;
  display: flex;
}
.ser_dropmenu .list-item.list-item_2 ul {
  flex-basis: 50%;
  border-left: 1px solid #9b9b9b;
}
.ser_dropmenu .list-item ul {
  padding: 16px 30px;
}
.ser_dropmenu .list-item:first-child {
  background: #0d0d0d;
}
.ser_dropmenu .list-item ul li a img {
  margin-right: 20px;
  width: 20px;
}
.ser_dropmenu .list-item ul li a {
  display: block;
}
.ser_dropmenu .list-item ul li a:hover {
  color: #2737c3;
}
.mainCat li {
  position: relative;
}
.mainCat li a:hover::after,
.mainCat li a.active::after {
  color: #2737c3;
  background: url(/public/images/arrow_menu.svg) no-repeat right center;
  content: "";
  position: absolute;
  right: 0;
  width: 39px;
  height: 7px;
  top: 22px;
}

@media (min-width: 992px) {
  .header .menu > ul > li.menu-item-has-children:hover .sub-menu {
    margin-top: 30px;
    visibility: visible;
    opacity: 1;
  }
}
.header .menu > ul > li .sub-menu > ul > li {
  line-height: 1;
}
.header .menu > ul > li .sub-menu > ul > li > a {
  display: inline-block;
  padding: 10px 0;
  font-size: 15px;
  color: #555555;
  transition: color 0.3s ease;
  text-decoration: none;
  text-transform: capitalize;
}
.header .menu > ul > li .single-column-menu {
  min-width: 280px;
  max-width: 350px;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li {
  line-height: 1;
  display: block;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
  padding: 10px 0;
  display: inline-block;
  font-size: 15px;
  color: #555555;
  transition: color 0.3s ease;
}
.header .menu > ul > li .sub-menu.mega-menu {
  left: 50%;
  transform: translateX(-50%);
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item {
  flex: 0 0 25%;
  padding: 0 15px;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .title {
  font-size: 16px;
  color: #ea4636;
  font-weight: 500;
  line-height: 1;
  padding: 10px 0;
}
.header
  .menu
  > ul
  > li
  .sub-menu.mega-menu-column-4
  > .list-item.text-center
  .title {
  text-align: center;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img {
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  margin-top: 10px;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a:hover,
.header .menu > ul > li .sub-menu > ul > li > a:hover,
.header .item-right a:hover,
.header .menu > ul > li:hover > a {
  color: #2737c3;
}
/* banner section */
.banner-section {
  /* background-image: url('../img/banner.jpg'); */
  background-size: cover;
  background-position: center;
  height: 700px;
  width: 100%;
  display: block;
}
.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

/*responsive*/
@media (max-width: 991px) {
  .header .item-center {
    /* order: 3; */
    /* flex: 0 0 100%; */
  }
  .header .item-left,
  .header .item-right {
    /* flex: 0 0 20%; */
  }
  .v-center {
    justify-content: space-between;
  }
  .header .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .header .mobile-menu-trigger span {
    display: block;
    height: 1px;
    background-color: #ffffff;
    width: 24px;
    position: relative;
  }
  .header .mobile-menu-trigger span:before,
  .header .mobile-menu-trigger span:after {
    content: "";
    position: absolute;
    left: 0;
    width: 32px;
    height: 1px;
    background-color: #fff;
  }
  .header .mobile-menu-trigger span:before {
    top: -11px;
  }
  .header .mobile-menu-trigger span:after {
    top: 9px;
  }
  .header .item-right {
    align-items: center;
  }

  .header .menu {
    position: fixed;
    width: 320px;
    background-color: #ffffff;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate(-100%);
    transition: all 0.5s ease;
    z-index: 1099;
  }
  .header .menu.active {
    transform: translate(0%);
  }
  .header .menu > ul > li {
    line-height: 1;
    margin: 0;
    display: block;
  }
  .header .menu > ul > li > a {
    line-height: 50px;
    height: 50px;
    padding: 0 50px 0 15px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #000;
  }
  .header .menu > ul > li > a svg {
    position: absolute;
    height: 18px;
    width: 46px;
    top: 15px;
    right: 0;
    text-align: center;
    line-height: 50px;
    transform: rotate(-90deg);
  }
  .header .menu .mobile-menu-head {
    display: flex;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: sticky;
    background-color: #ffffff;
    top: 0;
  }
  .header .menu .mobile-menu-head .go-back {
    height: 50px;
    width: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 16px;
    display: none;
  }
  .header .menu .mobile-menu-head.active .go-back {
    display: block !important;
  }
  .header .menu .mobile-menu-head .current-menu-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }
  .header .menu .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 25px;
  }
  .header .menu .menu-main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .header .menu > ul > li .sub-menu.mega-menu,
  .header .menu > ul > li .sub-menu {
    visibility: visible;
    opacity: 1;
    position: absolute;
    box-shadow: none;
    margin: 0;
    padding: 0px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 65px;
    max-width: none;
    min-width: auto;
    display: none;
    transform: translateX(0%);
    overflow-y: auto;
  }
  .header .menu > ul > li .sub-menu.active {
    display: block;
    transition: all 0.5s;
  }
  @keyframes slideLeft {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
  @keyframes slideRight {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }
  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img {
    margin-top: 0;
  }
  .header
    .menu
    > ul
    > li
    .sub-menu.mega-menu-column-4
    > .list-item.text-center
    .title {
    margin-bottom: 20px;
  }
  .header
    .menu
    > ul
    > li
    .sub-menu.mega-menu-column-4
    > .list-item.text-center:last-child
    .title {
    margin-bottom: 0px;
  }
  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item {
    flex: 0 0 100%;
    padding: 0px;
  }
  .header .menu > ul > li .sub-menu > ul > li > a,
  .header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
    display: block;
  }
  .header .menu > ul > li .sub-menu.mega-menu > .list-item > ul {
    margin-bottom: 15px;
  }
  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
  .ser_dropmenu {
    flex-wrap: wrap;
  }
  .ser_dropmenu .list-item {
    width: 100%;
    flex-basis: 100%;
  }
  .ser_dropmenu .list-item.list-item_2 {
    flex-wrap: wrap;
    flex-basis: 100%;
    width: 100%;
  }
  .ser_dropmenu .list-item.list-item_2 ul {
    width: 100%;
    flex-basis: 100%;
    padding: 16px 0;
    border: none;
  }
  .ser_dropmenu .list-item ul {
    padding: 16px 0;
  }
  .ser_dropmenu .list-item ul li {
    line-height: 40px;
  }
  .ser_dropmenu .list-item:first-child {
    background: none;
    border-bottom: 1px solid #484848;
  }
  .header .menu > ul > li .sub-menu {
    padding: 0;
  }
}
.startproject-container textarea::placeholder {
  color: white;
  opacity: 1; /* Ensures placeholder is fully visible */
}
.startproject-container input::placeholder {
  color: white;
  opacity: 1; /* Ensures placeholder is fully visible */
}
